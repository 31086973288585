@import 'normalize.css';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(to bottom, rgb(52, 64, 98), rgb(70, 87, 132), rgb(112, 134, 194), rgb(136, 152, 197), rgb(190, 200, 226));
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.container {
  padding-left: 10%;
  padding-right: 10%;
}

#carouselExampleCaptions {
  margin-left: auto;
  margin-right: auto;
}

.carousel-item {
  position: relative;
  height: 100vh;
}

.carousel-item img {
  width: 100%;         
  height: 100vh;       
  object-fit: cover;   
  object-position: center; 
}

.carousel-caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7); 
  padding: 20px;
  text-align: center;
}

.carousel-caption > p {
  padding-left: 80px;
  padding-right: 80px;
  color: rgb(193, 182, 182);
}

.navbar-brand {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  color: #343a40;
  letter-spacing: 10px;
  transition: color 0.3s ease;
}

.navbar-brand:hover {
  color: #376294; 
  text-decoration: none; 
}

.navbar {
  background-color: #c2c5ca; 
  padding: 1rem; 
  border-top: 10px solid rgb(0, 0, 0);
  border-bottom: 1px solid rgb(56, 52, 98);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}

.navbar-nav {
  display: flex; 
  gap: 1rem; 
  margin-left: auto; 
}

.navbar-nav .nav-link {
  color: #343a40; 
  font-weight: 600;
  transition: color 0.3s ease; 
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
}

.navbar-nav .nav-link:hover {
  color: #376294;
}

.btn-primary {
  color: aliceblue;
  background-color: #94374d;
  border: 1px solid white;
  border-radius: 15px;
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #239f50;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.whatsapp-button:hover {
  background-color: #ffffff;
}

.whatsapp-icon {
  width: 50px;
  height: 50px;
}

.form-connect {
  margin-top: 50px;
  max-width: 1000px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.form-control {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  color: aliceblue;
  border: none;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.form-control:focus {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.2);
  color: rgb(255, 255, 255);
}

.form-group label {
  color: aliceblue;
}

#contact-title {
  color: white;
  font-weight: 800;
  padding-top: 10%;
}

#contact-location {
  color: rgb(193, 182, 182);;
}

#contact-phone {
  color: white;
  font-size: large;
}

#projects-title {
  color: white;
  margin-top: 5%;
}

.logo-img {
  width: 150px; 
  height: auto; 
  max-height: 50px; 
  object-fit: contain;
}

.card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.container-projects {
  width: 90%;
  margin: 0 auto;
}

.carousel-image img,
.card-body img {
    width: 100%;
    height: 300px; 
    object-fit: cover; 
}

.footer {
  background-color: #343a40;
  color: white;
  margin-top: 10%;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
}

.footer a:hover {
  color: #d1d1d1;
  text-decoration: underline;
}

.container-footer {
  padding-left : 10%;
  padding-right: 10%;
}

.footer-section {
  padding: 0 20px;  
  position: relative;
  text-align: center;
}

.footer-section:not(:last-child) {
  border-right: 1px solid #ffffff;  
}

.footer-section h5 {
  margin-bottom: 15px;  
}

.footer-section ul {
  padding-left: 0;  
  list-style: none;  
}

.footer-section ul li {
  margin-bottom: 10px;  
}

.footer-section ul li a {
  color: #ffffff;
  text-decoration: none;
}

.footer-section ul li a:hover {
  color: #d1d1d1;  
  text-decoration: underline;
}

.about-company {
  background-color: #f8f9fa; 
  padding: 40px 0;  
  margin: 10%;
}

.about-company h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #343a40;  
  margin-bottom: 20px;
}

.about-company p {
  font-size: 1.2rem;
  color: #6c757d;  
  line-height: 1.6;  
  margin-bottom: 0;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  margin-top: 10%;
  font-weight: 600;
  color: aliceblue;
}

.intro-text {
  text-align: center;
  font-size: 1.2rem;
  color: #d1d1d1; 
  margin-bottom: 40px; 
}

.career-container {
  background-color: #f8f9fa; 
  padding: 30px; 
  margin-bottom: 40px; 
}

.benefits {
  display: flex;
  justify-content: space-around; 
  flex-wrap: wrap; 
  margin-top: 20px; 
}

.benefit {
  flex: 1; 
  margin: 10px; 
  padding: 20px; 
  border: 1px solid #dee2e6; 
  border-radius: 5px; 
  background-color: rgba(255, 255, 255, 0.8);
  transition: transform 0.2s; 
}

.benefit:hover {
  transform: translateY(-5px); 
}

.contact-title {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 40px; 
  font-weight: 600;
  color: aliceblue;
}

.contact-text {
  text-align: center;
  font-size: 1.2rem;
  color: #e0dfdf;
  margin-top: 20px; 
}

.closing-text {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 40px; 
  font-weight: 600;
  color: aliceblue;
}

.email-link {
  color: #073568; 
  text-decoration: none; 
  font-size: larger;
  font-weight: 800;
}

.email-link:hover {
  text-decoration: underline; 
}

/* Styles for small screens */
@media (max-width: 767px) {
  .carousel-caption h5 {
      font-size: 2.5rem; 
  }

  .carousel-caption p {
      font-size: 1.3rem; 
  }

  .carousel-caption > h5 {
    margin-top: -40%;
  }

  .benefits {
    flex-direction: column; 
    align-items: center; 
  }
  
  .benefit {
    width: 90%; 
    margin: 10px 0; 
  }
}

/* Styles for medium screens */
@media (min-width: 768px) and (max-width: 1199px) {
  .carousel-caption h5 {
      font-size: 3rem; 
  }

  .carousel-caption p {
      font-size: 1.5rem; 
  }

  .carousel-caption > h5 {
    margin-top: -20%;
  }

}

/* Styles for large screens */
@media (min-width: 1200px) {
  .carousel-caption h5 {
      font-size: 4rem; 
  }

  .carousel-caption p {
      font-size: 2.5rem; 
  }

  #carouselExampleCaptions {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%; 
  }

  .carousel-caption > h5 {
    margin-top: -20%;
  }
  
}



