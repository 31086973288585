.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
    background: transparent; /* Make it transparent */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
    z-index: 1001;
}

.modal-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
}

.modal-nav-icon {
    cursor: pointer;
    color: white;
    font-size: 36px;
    z-index: 1001; /* Ensure the icons are on top */
}

.left-icon {
    position: absolute;
    left: 10px;
}

.right-icon {
    position: absolute;
    right: 10px;
}

.modal-content img {
    max-width: 100%;
    max-height: 80vh; /* Restrict the height to 80% of the viewport height */
    margin: 0 auto;
    display: block;
}
